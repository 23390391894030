#services {
    padding-top: 6rem;
}

#services h2 {
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    #services h2 {
        font-size: 1.5rem;
    }
}