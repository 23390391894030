#form {
    padding-top: 5rem;
}

form {
    height: fit-content;
    width: 88%;
    margin: 0 auto;
    justify-content: space-between;
}

form input {
    height: 2.5rem;
    width: 48%;
    margin-bottom: 3rem;
}

form textarea {
    height: 8rem;
    width: 100%;
    resize: none;
    margin-bottom: 1rem;
}

form input,
form textarea {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    border: none;
    border-bottom: .1rem solid black;
    padding: .25rem 0;
    outline: none;
}

form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: black !important;
}

.submit {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    border: .1rem solid black;
    color: white;
    background-color: black;
    padding: .6rem .75rem .5rem;
    cursor: pointer;
    background-size: 0% 100%;
    background-image: linear-gradient(white, white);
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
}

.submit:hover {
    background-size: 100% 100%;
    color: black;
}

.toast {
    height: fit-content;
    width: fit-content;
    padding: .75rem 1.5rem;
    background-color: rgb(255, 255, 255, .32);
    box-shadow: .1rem .1rem 1rem rgb(0, 0, 0, .75);
    backdrop-filter: blur(1rem);
    position: fixed;
    right: -100%;
    bottom: 10%;
    transition: all .6s ease-in-out;
    z-index: 100;
    cursor: pointer;
}

.toast-active {
    right: 1%;
    bottom: 10%;
}

.toast i {
    margin-left: 1rem;
    color: green;
}

@media (max-width: 576px) {
    form {
        width: 100%;
    }
}