#all-projects {
    padding-top: 5rem;
}

#all-projects div {
    justify-content: space-evenly;
}

#all-projects .project {
    width: 26rem;
    margin-bottom: 3rem;
}

@media (max-width: 992px) {
    #all-projects .project {
        width: 20rem;
        min-width: 20rem;
        margin-left: .5rem;
        margin-right: .5rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 576px) {
    #all-projects .project {
        width: 90vw;
        min-width: 90vw;
    }
}