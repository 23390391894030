#nav {
    margin-top: 3rem;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    font-size: 1.75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .5rem;
    line-height: 1.5;
    z-index: 2;
}

.nav-logo span {
    font-size: 1.5rem;
    font-weight: 500;
}

.nav-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, .92);
    align-items: flex-end;
    padding-top: 10rem;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease-in-out;
    z-index: 1;
}

.nav-link {
    font-size: 3rem;
    font-weight: 500;
    color: white;
    margin: 2rem 5rem 0 0;
    padding: 0 .5rem;
    background-color: transparent;
    background-size: 0% 100%;
    background-image: linear-gradient(white, white);
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
}

.nav-link:hover {
    background-size: 100% 100%;
    color: #000;
}

.menu {
    height: 42px;
    width: 48px;
    justify-content: space-between;
    cursor: pointer;
    z-index: 2;
}

.line1,
.line2,
.line3 {
    height: 3px;
    width: 48px;
    background-color: black;
    transition: all .3s ease-in-out;
}

.menu .line2 {
    width: 32px;
    margin-left: auto;
}

.logo-invert {
    color: white;
}

.menu-active {
    height: 3px;
    width: 48px;
    background-color: white;
    transition: all .3s ease-in-out;
}

.line1-active {
    transform: rotate(-45deg) translate(-20px, 7px);
}

.line2-active {
    width: 0px;
    margin-left: auto;
    transition: all .3s ease-in-out;
}

.line3-active {
    transform: rotate(45deg) translate(-20px, -7px);
}

.active {
    opacity: 1;
    pointer-events: all;
}

@media (max-width: 992px) {
    .nav-link {
        margin: 2rem 3rem 0 0;
    }
}

@media (max-width: 576px) {
    #nav {
        margin-top: 1.25rem;
    }

    .nav-logo {
        font-size: 1.25rem;
    }

    .nav-logo span {
        font-size: 1rem;
    }

    .nav-links {
        padding-top: 6rem;
    }

    .nav-link {
        font-size: 2.25rem;
        margin: 2rem 1.25rem 0 0;
    }
}