html,
body {
    margin: 0 auto;
    background-color: white;
    scroll-behavior: smooth;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

::selection {
    background-color: rgb(0, 0, 0, .92);
    color: white;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:not(.skip):focus {
    outline-color: black;
}

a {
    width: fit-content;
    color: black;
    text-decoration: none;
    background-color: transparent;
    background-size: 0% 100%;
    background-image: linear-gradient(black, black);
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
}

a:not(.nav-logo, .nav-link, .hero, .about-image, .project, .see-all, .service-content a):hover {
    background-size: 100% 100%;
    color: white;
}

.flex {
    display: flex;
}

.wrap {
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.center {
    justify-content: center;
    align-items: center;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 2200px;
    padding: 0 5rem;
}

h1,
h2,
h3,
p {
    width: fit-content;
    transition: all .3s ease-in-out;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

p {
    line-height: 1.5;
}

@media (max-width: 992px) {
    .container {
        padding: 0 3rem;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 0 1.25rem;
    }

    h3 {
        font-size: 1.25rem;
    }
}