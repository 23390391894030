.intro {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 10;
    transition: all .5s ease-in-out;
}

.intro-active {
    transform: translateY(-100%);
}

.intro-logo {
    width: 9rem;
    filter: invert(100%);
    animation-name: show;
    animation-duration: 2s;
    animation-iteration-count: 1;
    ;
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}